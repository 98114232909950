<template>
  <ListBasicComponent end-point="shops" title="ショップ" :field="fields"></ListBasicComponent>
</template>

<script>
import ListBasicComponent from "@/components/views/ListBasicComponent";

export default {
  name: 'Shops',
  components: {ListBasicComponent},
  data() {
    return {
      fields: [
        // { key: 'select', label: '' },
        {key: 'id', label: 'ID'},
        {key: 'name', label: '名前'},
        {key: 'address', label: '住所'},
        {key: 'tel', label: '電話番号'},
        {key: 'zipcode', label: '郵便番号'},
        {key: 'rate', label: '料率'},
        {key: 'action', label: ''},
      ]
    }
  }
}
</script>
